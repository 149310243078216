import React from 'react';
import Helmet from 'react-helmet';

export interface SEOProps {
    title?: string,
    description?: string
}

const SEO: React.FC<SEOProps> = ({ title, description }) => {
    return (
        <Helmet
            title={title ? `${title} | Long Run Mopeds` : 'Long Run Mopeds - High Quality New and Pre-Owned Mopeds'}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: '@NateGiraudeau',
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ]}
        />
    )
}

export default SEO;